var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$can('update', 'Members'))?_c('p',[_vm._v("You can update members (TESTING).")]):_vm._e(),_c('b-card-group',{attrs:{"columns":""}},[_c('b-card',{attrs:{"header":"Info"}},[_c('b-card-text',[_vm._v(" This is the Dashboard and these are Cards. They could hold various data, intended for a quick glance at your over-all account. They could be good to have an idea of what needs attention without diving deeper into the app. ")]),_c('b-card-text',[_vm._v(" This would be a group project. I would need feedback from everyone to make them effective. Otherwise, we'll just skip the Dashboard page. ")])],1),_c('b-card',{attrs:{"title":"Call Stats"}},[_c('ChartBar',{attrs:{"type":"line","wrapper":false,"data":{
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
            {
              label: 'Calls',
              backgroundColor: 'rgba(220, 220, 220, 0.2)',
              borderColor: 'rgba(220, 220, 220, 1)',
              pointBackgroundColor: 'rgba(220, 220, 220, 1)',
              pointBorderColor: '#fff',
              data: [40, 20, 12, 39, 10, 40, 39],
            },
            {
              label: 'Booked',
              backgroundColor: 'rgba(151, 187, 205, 0.2)',
              borderColor: 'rgba(151, 187, 205, 1)',
              pointBackgroundColor: 'rgba(151, 187, 205, 1)',
              pointBorderColor: '#fff',
              data: [25, 4, 4, 20, 6, 15, 7],
            },
            {
              label: 'Enrolled',
              backgroundColor: 'rgba(51, 287, 305, 0.2)',
              borderColor: 'rgba(151, 187, 205, 1)',
              pointBackgroundColor: 'rgba(151, 187, 205, 1)',
              pointBorderColor: '#fff',
              data: [25, 8, 2, 9, 1, 10, 5],
            } ],
        }}}),_c('b-button-group',{staticClass:"mt-2 w-100"},[_c('b-button',[_vm._v("Action One")]),_c('b-button',[_vm._v("Action Two")]),_c('b-button',[_vm._v("Action Three")])],1)],1),_c('b-card',{attrs:{"header":"Quote"}},[_c('blockquote',{staticClass:"blockquote mb-0"},[_c('p',[_vm._v(" There are two types of people who will tell you that you cannot make a difference in this world: those who are afraid to try and those who are afraid you will succeed. ")]),_c('footer',{staticClass:"blockquote-footer"},[_vm._v("Ray Goforth")])])]),_c('b-card',{attrs:{"header":"Quote"}},[_c('blockquote',{staticClass:"blockquote mb-0"},[_c('p',[_vm._v("Success is walking from failure to failure with no loss of enthusiasm.")]),_c('footer',{staticClass:"blockquote-footer"},[_vm._v("Winston Churchill")])])]),_c('b-card',{attrs:{"title":"Cat Pic","img-src":"https://placekitten.com/500/350","img-alt":"Image","img-top":""}},[_c('b-card-text',[_vm._v(" This card has a cute image of a cat becaue, well, you know... Cats. ")]),_c('b-card-text',{staticClass:"small text-muted"},[_vm._v("Last updated as you visited this page")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }