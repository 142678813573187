<template>
  <div>
    <p v-if="$can('update', 'Members')">You can update members (TESTING).</p>
    <b-card-group columns>
      <b-card header="Info">
        <b-card-text>
          This is the Dashboard and these are Cards. They could hold various data,
          intended for a quick glance at your over-all account. They could be good to have
          an idea of what needs attention without diving deeper into the app.
        </b-card-text>
        <b-card-text>
          This would be a group project. I would need feedback from everyone to make them
          effective. Otherwise, we'll just skip the Dashboard page.
        </b-card-text>
      </b-card>

      <b-card title="Call Stats">
        <ChartBar
          type="line"
          :wrapper="false"
          :data="{
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
              {
                label: 'Calls',
                backgroundColor: 'rgba(220, 220, 220, 0.2)',
                borderColor: 'rgba(220, 220, 220, 1)',
                pointBackgroundColor: 'rgba(220, 220, 220, 1)',
                pointBorderColor: '#fff',
                data: [40, 20, 12, 39, 10, 40, 39],
              },
              {
                label: 'Booked',
                backgroundColor: 'rgba(151, 187, 205, 0.2)',
                borderColor: 'rgba(151, 187, 205, 1)',
                pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                pointBorderColor: '#fff',
                data: [25, 4, 4, 20, 6, 15, 7],
              },
              {
                label: 'Enrolled',
                backgroundColor: 'rgba(51, 287, 305, 0.2)',
                borderColor: 'rgba(151, 187, 205, 1)',
                pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                pointBorderColor: '#fff',
                data: [25, 8, 2, 9, 1, 10, 5],
              },
            ],
          }"
        />
        <b-button-group class="mt-2 w-100">
          <b-button>Action One</b-button>
          <b-button>Action Two</b-button>
          <b-button>Action Three</b-button>
        </b-button-group>
      </b-card>

      <b-card header="Quote">
        <blockquote class="blockquote mb-0">
          <p>
            There are two types of people who will tell you that you cannot make a
            difference in this world: those who are afraid to try and those who are afraid
            you will succeed.
          </p>
          <footer class="blockquote-footer">Ray Goforth</footer>
        </blockquote>
      </b-card>

      <b-card header="Quote">
        <blockquote class="blockquote mb-0">
          <p>Success is walking from failure to failure with no loss of enthusiasm.</p>
          <footer class="blockquote-footer">Winston Churchill</footer>
        </blockquote>
      </b-card>

      <b-card
        title="Cat Pic"
        img-src="https://placekitten.com/500/350"
        img-alt="Image"
        img-top
      >
        <b-card-text>
          This card has a cute image of a cat becaue, well, you know... Cats.
        </b-card-text>
        <b-card-text class="small text-muted"
          >Last updated as you visited this page</b-card-text
        >
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import ChartBar from '../components/charts/ChartBar.vue'

export default {
  name: 'Dashboard',
  components: { ChartBar },
  data: () => ({}),
}
</script>
